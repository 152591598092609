import React, { createContext, useState, useEffect, useContext } from 'react';
import {
  deleteEntities,
  getEntities,
  calcIndex,
  createEntity as createEntityRemote,
  updateEntity as updateEntityRemote,
} from '../firebase';
import { contractRentAdjustment } from '../model/ContractRentAdjustment';
import { UserContext } from '../provider/AuthProvider';

export const DataContext = createContext();
const VAT_PERCENTAGE = 0.21;

const DataProvider = ({ children }) => {
  const [user] = useContext(UserContext);
  const [data, setData] = useState({
    owner: [],
    property: [],
    contract: [],
    rental_contract: [],
    contractRentAdjustment: [],
    portfolio: [],
    position: [],
    entity: [],
    invoice: [],
    counter: 0,
    ready: false,
  });

  const getEntitiesFromRemote = (entityName, secured) => {
    setData((state) => ({ ...state, counter: state.counter + 1 }));
    fetchEntities(
      entityName,
      (entities) => {
        setData((data) => ({
          ...data,
          [entityName]: entities,
          counter: data.counter - 1,
          ready: data.counter === 1,
        }));
      },
      secured,
    );
  };

  useEffect(() => {
    getEntitiesFromRemote('owner', true);
    getEntitiesFromRemote('property', true);
    getEntitiesFromRemote('contract', true);
    //getEntitiesFromRemote('contractRentAdjustment');
    getEntitiesFromRemote('portfolio', true);
    getEntitiesFromRemote('position', true);
    getEntitiesFromRemote('entity', true);
    getEntitiesFromRemote('invoice', true);
    getEntitiesFromRemote('rental_contract', true);
  }, []);

  const fetchEntities = (entityName, success, secured) => {
    getEntities(
      entityName,
      (entities) => {
        success(entities);
      },
      secured ? user.uid : false,
    );
  };

  const createEntity = (entity, details, secured) => {
    createEntityRemote(entity, details, secured ? user.uid : false);
  };

  const updateEntity = (entity, details, secured) => {
    updateEntityRemote(entity, details, secured ? user.uid : false);
  };

  const refresh = (item, secured) => {
    getEntitiesFromRemote(item, secured);
  };

  const del_ent = (entity_name, secured, success) =>
    deleteEntities(entity_name, secured ? user.uid : false).then((res) => {
      refresh(entity_name);
      success && success(res);
    });

  const calculateIndex = (indexMonth, indexYear) => {
    //filter contracts for the month

    let rentalContractsToIndex = data.rental_contract;

    rentalContractsToIndex = rentalContractsToIndex.map((contract) => {
      const [startDay, startMonth, startYear] = contract.detail.startdate.split(
        '/',
      );
      const [signDay, signMonth, signYear] = contract.detail.signdate.split(
        '/',
      );

      return {
        ...contract,
        indexResult: calcIndex(
          parseFloat(contract.detail.rent),
          `${signYear}-${signMonth}`,
          `${startYear}-${startMonth}`,
          indexYear,
          contract.detail.region,
        ),
      };
    });

    console.dir(rentalContractsToIndex);

    rentalContractsToIndex = rentalContractsToIndex
      .filter(
        (entityDetail) =>
          entityDetail.indexResult &&
          entityDetail.indexResult.result === 0 &&
          parseInt(entityDetail.indexResult.newRent.fromMonth) ==
            parseInt(indexMonth) &&
          entityDetail.indexResult.newRent.fromYear === indexYear,
      )
      .map((entityDetail) => {
        let indexResult = entityDetail.indexResult;

        let monthlyDue =
          parseFloat(indexResult.newRent.rent) +
          (entityDetail.detail.charges && entityDetail.detail.charges.length > 0
            ? parseFloat(entityDetail.detail.charges)
            : 0) +
          (entityDetail.detail.provrv && entityDetail.detail.provrv.length > 0
            ? parseFloat(entityDetail.detail.provrv)
            : 0);

        let vatAmount = entityDetail.detail.vat
          ? monthlyDue * VAT_PERCENTAGE
          : 0;

        vatAmount = parseFloat(vatAmount.toFixed(2));
        monthlyDue = parseFloat(monthlyDue.toFixed(2));
        monthlyDue = monthlyDue + vatAmount;

        vatAmount = vatAmount.toFixed(2);
        monthlyDue = monthlyDue.toFixed(2);

        return {
          ...contractRentAdjustment(),
          contract: {
            name: entityDetail.tenant.name,
            address: entityDetail.tenant.usePropertAddress
              ? entityDetail.property.address
              : entityDetail.tenant.address,
            detail: {
              ...entityDetail.detail,
              caption: entityDetail.tenant.caption,
              rv:
                entityDetail.detail.provrv &&
                entityDetail.detail.provrv.length > 0
                  ? parseFloat(entityDetail.detail.provrv).toFixed(2)
                  : undefined,
              charges:
                entityDetail.detail.charges &&
                entityDetail.detail.charges.length > 0
                  ? parseFloat(entityDetail.detail.charges).toFixed(2)
                  : undefined,
            },
          },
          detail: {
            indexBaseMonthYear: `${indexResult.baseRent.month}/${indexResult.baseRent.year}`,
            baseIndexRebased: indexResult.startIndex.index,
            newIndexMonthYear: `${indexResult.currentIndex.month}/${indexResult.currentIndex.year}`,
            newIndex: indexResult.currentIndex.index,
            rent: indexResult.newRent.rent,
            vat: vatAmount,
            type: entityDetail.property.type,
            monthlyDue: monthlyDue,
            nextPaymentMonth: `${indexResult.newRent.fromMonth}/${indexResult.newRent.fromYear}`,
          },
          owner: data.owner.filter(
            (owner) => owner.own_ref === entityDetail.owner.key,
          )[0],
          property: entityDetail.property,
        };
      });

    setData((data) => ({
      ...data,
      contractRentAdjustment: rentalContractsToIndex,
      contractRentAdjustmentData: { indexMonth, indexYear },
    }));
  };

  return (
    <DataContext.Provider
      value={[
        data,
        {
          refresh,
          del_ent,
          calculateIndex,
          createEntity,
          updateEntity,
          fetchEntities,
        },
      ]}
    >
      {data.ready ? children : <div>loading data...</div>}
    </DataContext.Provider>
  );
};

export default DataProvider;
