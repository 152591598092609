import firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

var firebaseConfig = {
  apiKey: 'AIzaSyBVF9rxH47pldQTvXu22jS5vFxGognQjmc',
  authDomain: 'puur-eva-be.firebaseapp.com',
  databaseURL: 'https://puur-eva-be.firebaseio.com',
  projectId: 'puur-eva-be',
  storageBucket: 'puur-eva-be.appspot.com',
  messagingSenderId: '8429361541',
  appId: '1:8429361541:web:026b023a0f52ba71eb0cec',
  measurementId: 'G-MHNB95Y6VD',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();

const providerGoogle = new firebase.auth.GoogleAuthProvider();
const providerFacebook = new firebase.auth.FacebookAuthProvider();

export const signInWithFacebook = (onSuccess) => {
  signInWithRedirect(providerFacebook, onSuccess);
};

export const signInWithEmail = (email, password, onSuccess, onFailiure) => {
  firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(() => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(async (user) => {
          /*
          const userRef = await firestore
            .collection(`users`)
            .doc(user.user.uid);
          let userDoc = await userRef.get();

          if (!userDoc.exists) {
            await userRef.set({ email, name: 'Unkown' });
            userDoc = await userRef.get();
          }

          let result = userDoc.data();

          onSuccess({ ...result, uid: user.user.uid });*/
          onSuccess(user);
        })
        .catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          onFailiure && onFailiure(`Error: ${errorCode} ${errorMessage}`);
        });
    });
};

export const getUser = (success) => {
  const user = auth.currentUser;

  if (!user) {
    success(undefined);
    return;
  }

  const userRef = firestore.collection(`users`).doc(user.uid);

  userRef.get().then((userDoc) => {
    let result = userDoc.data();
    success({ ...result, uid: user.uid });
  });
};

export const signInWithGoogle = (onSuccess) => {
  signInWithRedirect(providerGoogle, onSuccess);
};

const signInWithRedirect = (provider, onSuccess) => {
  auth.signInWithRedirect(provider);
  firebase
    .auth()
    .getRedirectResult()
    .then(function (result) {
      // The signed-in user info.
      result.user && onSuccess && onSuccess(result.user.uid);
    })
    .catch(function (error) {
      // Handle Errors here.
      console.error(error);
    });
};

export const signInWithGooglePopUp = (onSuccess) =>
  auth.signInWithPopup(providerGoogle).then(
    (result) => {
      console.log(result);
      onSuccess && onSuccess(result.user.uid);
    },
    (err) => console.error(err),
  );
export const signOut = () => auth.signOut();

export const createEntity = async (entity, details, uid) => {
  const documentPath = `${uid ? `user_data/${uid}/` : ``}${entity}`;
  const entityCollectionRef = firestore.collection(documentPath);

  console.log(`Create doc: ${documentPath}`);
  const entityReference = await entityCollectionRef.add(details);
  console.dir(`Entity ${entity} created: ${entityReference.id}  `);
  return { ...details, _id: entityReference.id };
};

export const updateEntity = async (entity, details, uid) => {
  const documentPath = `${uid ? `user_data/${uid}/` : ``}${entity}/${
    details._id
  }`;
  console.log(`Update doc: ${documentPath}`);

  const entityRef = await firestore.doc(documentPath);
  entityRef.update({ ...details });
};

export const getEntity = (entity, _id, success, uid) => {
  const documentPath = `${uid ? `user_data/${uid}/` : ``}${entity}/${_id}`;
  console.log(`Fetch doc: ${documentPath}`);
  const entityRef = firestore.doc(documentPath);
  entityRef.get().then((snapshot) => {
    snapshot.exists && success(snapshot.data());
  });
};

export const getEntities = (entity, setEntityList, uid) => {
  const collectionPath = `${uid ? `user_data/${uid}/` : ``}${entity}`;

  console.log(uid);

  console.log(collectionPath);
  const ownersCollRef = firestore.collection(collectionPath);

  ownersCollRef.get().then((snapshot) => {
    const docArr = snapshot.docs;
    const allEntities = docArr.map((it) => {
      return { ...it.data(), _id: it.id };
    });
    setEntityList(allEntities);
  });
};

export const deleteEntities = async (entityName, uid) => {
  const ownersCollRef = firestore.collection(
    `${uid ? `user_data/${uid}/` : ``}${entityName}`,
  );

  let deleteOperations = [];

  await ownersCollRef.get().then((snapshot) => {
    const docArr = snapshot.docs;
    const allEntities = docArr.map((it) => {
      deleteOperations.push(
        new Promise((resolve) =>
          it.ref
            .delete()
            .then(() =>
              resolve(`Entity ${entityName} with id: ${it.id} DELETED`),
            ),
        ),
      );
    });
  });
  return Promise.all(deleteOperations);
};

export const createProperty = async (details) => {
  const ownersCollectionRef = firestore.collection(`properties`);
  const ownersReference = await ownersCollectionRef.add(details);
  return { ...details, _id: ownersReference.id };
};

export const updateProperty = async (details) => {
  const ownerRef = await firestore.doc(`properties/${details.id}`);
  ownerRef.update({ ...details });
};

export const calcIndex = (rent, signDate, startDate, indexYear, region) => {
  if (signDate && startDate) {
    const [signYear, signMonth] = signDate.split('-');
    const [startYear, startMonth] = startDate.split('-');

    let [baseIndexYear, baseIndexMonth] =
      signMonth - 1 > 0 ? [signYear, signMonth - 1] : [signYear - 1, 12];

    if (region === 1 && signYear >= 2019)
      //vlaams gewest ondertekend vanaf 01/2019
      [baseIndexYear, baseIndexMonth] =
        startMonth - 1 > 0 ? [startYear, startMonth - 1] : [startYear - 1, 12];

    //check if contract sign date < start date
    if (
      parseInt(`${signYear}${signMonth}`) >
      parseInt(`${startYear}${startMonth}`)
    )
      return {
        result: -1,
        msg: {
          nl:
            'Datum ondertekening mag niet recenter zijn dan de datum inwerkingtreding zijn. Corigeer de ingevoerde gegevens.',
        },
      };
    if (parseInt(`${startYear}`) >= indexYear)
      return {
        result: -1,
        msg: {
          nl:
            'Jaar van indexate moet groter zijn dan het jaar van inwerkingtreding. Corigeer de ingevoerde gegevens.',
        },
      };

    if (signYear && signMonth && startYear && startMonth && rent && indexYear) {
      let startIndex =
        IndexFigures[baseIndexYear][`${baseIndexMonth}`.padStart(2, '0')];

      let [currentIndexMonth, currentIndexYear] =
        startMonth - 1 > 0
          ? [`${startMonth - 1}`.padStart(2, '0'), indexYear]
          : ['12', indexYear - 1];

      if (
        !IndexFigures[currentIndexYear] ||
        !IndexFigures[currentIndexYear][currentIndexMonth]
      )
        return {
          result: -1,
          msg: {
            nl: `Indexcijfer is momenteel nog niet bekend voor de maand ${currentIndexMonth}/${currentIndexYear}.`,
          },
        };

      let currentIndex = IndexFigures[currentIndexYear][currentIndexMonth];

      return {
        result: 0,
        baseRent: {
          rent: rent.toFixed(2),
          month: signMonth,
          year: signYear,
        },
        newRent: {
          rent: ((rent * currentIndex) / startIndex).toFixed(2),
          fromMonth: startMonth,
          fromYear: indexYear,
        },
        startIndex: {
          index: startIndex,
          monthYear: `${`${baseIndexMonth}`.padStart(2, '0')}-${baseIndexYear}`,
          month: `${baseIndexMonth}`.padStart(2, '0'),
          year: baseIndexYear,
        },
        currentIndex: {
          index: currentIndex,
          monthYear: `${currentIndexMonth}-${currentIndexYear}`,
          month: currentIndexMonth,
          year: currentIndexYear,
        },
      };
    }
  }
};

export const IndexFigures = {
  1990: {
    '01': 60.72,
    '02': 60.86,
    '03': 61.01,
    '04': 61.29,
    '05': 61.31,
    '06': 61.35,
    '07': 61.57,
    '08': 61.92,
    '09': 62.49,
    10: 62.91,
    11: 62.68,
    12: 62.61,
  },
  1991: {
    '01': 63.08,
    '02': 63.29,
    '03': 63.03,
    '04': 63.06,
    '05': 63.29,
    '06': 63.57,
    '07': 63.92,
    '08': 64.12,
    '09': 64.07,
    10: 64.31,
    11: 64.47,
    12: 64.35,
  },
  1992: {
    '01': 64.52,
    '02': 64.72,
    '03': 64.74,
    '04': 64.81,
    '05': 65.04,
    '06': 65.25,
    '07': 65.55,
    '08': 65.44,
    '09': 65.56,
    10: 65.7,
    11: 65.91,
    12: 65.9,
  },
  1993: {
    '01': 66.35,
    '02': 66.52,
    '03': 66.63,
    '04': 66.69,
    '05': 66.79,
    '06': 66.8,
    '07': 67.25,
    '08': 67.53,
    '09': 67.41,
    10: 67.49,
    11: 67.58,
    12: 67.68,
  },
  1994: {
    '01': 69.80634,
    '02': 70.0176,
    '03': 69.969312,
    '04': 70.07796,
    '05': 70.283184,
    '06': 70.40994,
    '07': 70.89282,
    '08': 70.971288,
    '09': 70.880748,
    10: 70.766064,
    11: 70.7721,
    12: 70.796244,
  },
  1995: {
    '01': 71.122188,
    '02': 71.357592,
    '03': 71.291196,
    '04': 71.363628,
    '05': 71.31534,
    '06': 71.363628,
    '07': 71.846508,
    '08': 72.057768,
    '09': 71.810292,
    10: 71.695608,
    11: 71.810292,
    12: 71.792184,
  },
  1996: {
    '01': 72.347496,
    '02': 72.486324,
    '03': 72.510468,
    '04': 72.52254,
    '05': 72.37164,
    '06': 72.432,
    '07': 72.939024,
    '08': 73.138212,
    '09': 72.920916,
    10: 73.0356,
    11: 73.108032,
    12: 73.210644,
  },
  1997: {
    '01': 73.693524,
    '02': 73.566768,
    '03': 73.222716,
    '04': 73.234788,
    '05': 73.30722,
    '06': 73.440012,
    '07': 74.110008,
    '08': 74.146224,
    '09': 73.844424,
    10: 73.862532,
    11: 74.073792,
    12: 74.049648,
  },
  1998: {
    '01': 74.111874,
    '02': 74.293824,
    '03': 74.19921,
    '04': 74.555832,
    '05': 74.956122,
    '06': 74.883342,
    '07': 75.065292,
    '08': 74.774172,
    '09': 74.752338,
    10: 74.759616,
    11: 74.74506,
    12: 74.752338,
  },
  1999: {
    '01': 75.014346,
    '02': 75.189018,
    '03': 75.196296,
    '04': 75.378246,
    '05': 75.501972,
    '06': 75.370968,
    '07': 75.385524,
    '08': 75.203574,
    '09': 75.349134,
    10: 75.43647,
    11: 75.567474,
    12: 75.705756,
  },
  2000: {
    '01': 75.902262,
    '02': 76.098768,
    '03': 76.266162,
    '04': 76.477224,
    '05': 76.571838,
    '06': 76.731954,
    '07': 76.921182,
    '08': 76.957572,
    '09': 77.29236,
    10: 77.175912,
    11: 77.47431,
    12: 77.43792,
  },
  2001: {
    '01': 77.496144,
    '02': 77.750874,
    '03': 77.976492,
    '04': 78.558732,
    '05': 78.937188,
    '06': 79.191918,
    '07': 79.271976,
    '08': 79.322922,
    '09': 79.446648,
    10: 79.490316,
    11: 79.643154,
    12: 79.497594,
  },
  2002: {
    '01': 80.007054,
    '02': 80.123502,
    '03': 80.298174,
    '04': 80.138058,
    '05': 80.31273,
    '06': 80.123502,
    '07': 80.378232,
    '08': 80.341842,
    '09': 80.509236,
    10: 80.370954,
    11: 80.392788,
    12: 80.45829,
  },
  2003: {
    '01': 80.742132,
    '02': 81.193368,
    '03': 81.382596,
    '04': 81.317094,
    '05': 81.200646,
    '06': 81.40443,
    '07': 81.557268,
    '08': 81.65916,
    '09': 81.855666,
    10: 81.62277,
    11: 81.775608,
    12: 81.746496,
  },
  2004: {
    '01': 82.001226,
    '02': 82.255956,
    '03': 82.27779,
    '04': 82.6053,
    '05': 82.729026,
    '06': 82.71447,
    '07': 82.9692,
    '08': 82.991034,
    '09': 83.027424,
    10: 83.325822,
    11: 83.318544,
    12: 83.15115,
  },
  2005: {
    '01': 83.464104,
    '02': 83.900784,
    '03': 84.35202,
    '04': 84.330186,
    '05': 84.490302,
    '06': 84.635862,
    '07': 84.977928,
    '08': 84.992484,
    '09': 84.89787,
    10: 84.759588,
    11: 85.021596,
    12: 85.087098,
  },
  2006: {
    '01': 85.13496,
    '02': 85.54068,
    '03': 85.47444,
    '04': 85.7808,
    '05': 86.0706,
    '06': 86.05404,
    '07': 86.319,
    '08': 86.42664,
    '09': 86.41008,
    10: 86.37696,
    11: 86.59224,
    12: 86.67504,
  },
  2007: {
    '01': 86.87376,
    '02': 87.32088,
    '03': 87.13044,
    '04': 87.42024,
    '05': 87.22152,
    '06': 87.17184,
    '07': 87.5196,
    '08': 87.49476,
    '09': 87.52788,
    10: 87.92532,
    11: 88.53804,
    12: 88.96032,
  },
  2008: {
    '01': 89.2998,
    '02': 90.01188,
    '03': 90.51696,
    '04': 90.65772,
    '05': 91.2456,
    '06': 91.59336,
    '07': 92.09016,
    '08': 91.80864,
    '09': 92.0322,
    10: 92.14812,
    11: 91.98252,
    12: 92.10672,
  },
  2009: {
    '01': 92.2806,
    '02': 92.529,
    '03': 91.96596,
    '04': 92.04876,
    '05': 91.87488,
    '06': 91.494,
    '07': 91.47744,
    '08': 91.62648,
    '09': 91.46088,
    10: 91.60992,
    11: 91.701,
    12: 91.87488,
  },
  2010: {
    '01': 92.20608,
    '02': 92.6532,
    '03': 92.82708,
    '04': 93.01752,
    '05': 93.33216,
    '06': 93.34872,
    '07': 93.44808,
    '08': 93.51432,
    '09': 93.80412,
    10: 93.94488,
    11: 94.0194,
    12: 94.25952,
  },
  2011: {
    '01': 94.70664,
    '02': 95.2614,
    '03': 95.54292,
    '04': 95.69196,
    '05': 96.03144,
    '06': 96.40404,
    '07': 96.55308,
    '08': 96.45372,
    '09': 96.65244,
    10: 96.84288,
    11: 97.2072,
    12: 97.30656,
  },
  2012: {
    '01': 97.911,
    '02': 98.50716,
    '03': 98.54028,
    '04': 98.52372,
    '05': 98.6562,
    '06': 98.532,
    '07': 98.70588,
    '08': 98.92116,
    '09': 98.96256,
    10: 99.25236,
    11: 99.3186,
    12: 99.40968,
  },
  2013: {
    '01': 99.36,
    '02': 99.58356,
    '03': 99.774,
    '04': 99.76572,
    '05': 100.03068,
    '06': 100.19628,
    '07': 100.23768,
    '08': 100.09692,
    '09': 100.03068,
    10: 100.17972,
    11: 100.28736,
    12: 100.41156,
  },
  2014: {
    '01': 100.6,
    '02': 100.75,
    '03': 100.79,
    '04': 100.44,
    '05': 100.29,
    '06': 100.34,
    '07': 100.46,
    '08': 100.12,
    '09': 100.06,
    10: 100.28,
    11: 100.28,
    12: 100.4,
  },
  2015: {
    '01': 100.61,
    '02': 100.89,
    '03': 100.73,
    '04': 101.12,
    '05': 101.16,
    '06': 101.33,
    '07': 101.37,
    '08': 101.61,
    '09': 101.85,
    10: 102.27,
    11: 102.28,
    12: 102.23,
  },
  2016: {
    '01': 102.42,
    '02': 102.53,
    '03': 103.47,
    '04': 103.53,
    '05': 103.77,
    '06': 103.74,
    '07': 103.93,
    '08': 103.97,
    '09': 103.68,
    10: 103.86,
    11: 103.97,
    12: 104.05,
  },
  2017: {
    '01': 104.65,
    '02': 105.06,
    '03': 105.32,
    '04': 105.46,
    '05': 105.42,
    '06': 105.29,
    '07': 105.63,
    '08': 105.68,
    '09': 105.51,
    10: 105.84,
    11: 105.85,
    12: 106.15,
  },
  2018: {
    '01': 106.37,
    '02': 106.54,
    '03': 106.71,
    '04': 106.89,
    '05': 106.99,
    '06': 107.01,
    '07': 107.44,
    '08': 107.55,
    '09': 107.52,
    10: 108.26,
    11: 108.48,
    12: 108.45,
  },
  2019: {
    '01': 108.5,
    '02': 108.78,
    '03': 109.04,
    '04': 108.98,
    '05': 108.89,
    '06': 109.02,
    '07': 109.07,
    '08': 109.07,
    '09': 108.58,
    10: 108.98,
    11: 109,
    12: 109.18,
  },
  2020: {
    '01': 109.72,
    '02': 109.87,
    '03': 109.96,
    '04': 110.22,
    '05': 110.1,
    '06': 110.05,
    '07': 110.16,
    '08': 110.2,
    '09': 109.78,
    10: 110.11,
    11: 109.91,
  },
  2021: {},
};

export default firebase;
