import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { EntityList } from '../components/EntityList';
import { createEntity, getEntities } from '../firebase';
import { Switch, Route, useParams } from 'react-router-dom';

const downloadTxtFile = (data) => {
  [
    {
      name: 'newletter',
      fields: ['first', 'last', 'email', 'newsletter', 'campaign', 'time'],
    },
  ].map((it) => {
    let content = it.fields.reduce((acc, it) => acc + `${it},`, '') + '\n';

    data.map((i) => {
      content +=
        it.fields.map((fieldN) => {
          return fieldN.split('.').reduce((acc, path) => acc[path], i);
        }) + '\n';
    });

    const element = document.createElement('a');
    const file = new Blob([content], {
      type: 'text/plain;charset=utf-8',
    });
    element.href = URL.createObjectURL(file);
    element.download = `${it.name}.csv`;
    document.body.appendChild(element);
    element.click();
  });
};

const Blog = ({ initState }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 120000);
  }, []);
  return (
    <div>
      {' '}
      <iframe
        aos="fade"
        aos-duration="500"
        id="inlineFrameExample"
        title="Inline Frame Example"
        frameborder="0"
        style={{
          //display: !show ? 'block' : 'none',
          position: 'relative',
          border: 'none',
          zIndex: 0,
          height: 'calc(100vh - 4px)',
          width: 'calc(100vw - 4px)',
          boxSizing: 'border-box',
        }}
        src="https://www.puur-eva.be/post/bootcamp-in-de-bergen"
      ></iframe>
      <div
        style={{
          display: show ? 'inline' : 'none',
          position: 'fixed',
          top: '0',
          height: '100vh',
          width: '100vw',
          zIndex: 1,
        }}
      >
        <button
          disabled
          onClick={() => setShow(false)}
          style={{
            display: show ? 'inline' : 'none',
            height: '100%',
            width: '100%',
            backgroundColor: 'darkgray',
            opacity: '95%',
          }}
        ></button>
      </div>
      <div
        style={{
          display: show ? 'inline' : 'none',
          position: 'fixed',
          zIndex: 2,
          top: '0',
          width: '100vw',
        }}
      >
        <QueryForm
          initState={initState}
          info={{
            title: `Blog post - Bootcamp`,
            image: '/bootcamp.jpg',
            description: 'Verder lezen?',
            buttonText: 'verder lezen',
          }}
          checkOk={(state, setView) => {
            createEntity(`/user_data/${initState.uid}/email`, {
              ...state,
              time: Date.now(),
            }).then(() => {
              setView(1);
            });
          }}
          success={() => {
            setShow(false);
            //window.location.href = 'https://www.puur-eva.be/post/bootcamp-in-de-bergen';
          }}
        />
      </div>
    </div>
  );
};

const PuurEva = ({ user }) => {
  const history = useHistory();

  const [registrations, setRegistrations] = React.useState([]);

  const initState = {
    first: '',
    last: '',
    email: '',
    newsletter: true,
    uid: 'NdWrtTboE7PRqUZgyVflMzxM7KX2',
    campaign: 'brain',
    time: Date.now(),
  };

  React.useEffect(() => {
    /*    document.getElementsByTagName('head')[0].innerHTML =
      `<title>Brain food</title>
    <meta name="description" content="Maak je brein blij met deze voeding">
    <meta property="og:title" content="Examens? Brain food voor een goed stel hersenen." />
    <meta property="og:description" content="Maak je brein blij met deze voeding" />
    <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/puur-eva-be.appspot.com/o/brain.jpg?alt=media&token=2d3122eb-f747-4d90-be2b-9bed5ea94c94" />
    <meta property="og:url" content="https://www.puur-eva.be" />` +
      document.getElementsByTagName('head')[0].innerHTML;*/

    user && getEntities('email', setRegistrations, initState.uid);
  }, []);

  return (
    <>
      {user && (
        <>
          <div className="grid grid-cols-5 ">
            <>
              <div className="font-semibold border-b-2 border-black">
                Voornaam
              </div>
              <div className="font-semibold border-b-2 border-black">
                Familienaam
              </div>
              <div className="font-semibold border-b-2 border-black">email</div>
              <div className="font-semibold border-b-2 border-black">
                Datum en UUR
              </div>
              <div className="font-semibold border-b-2 border-black">
                campagne
              </div>
            </>

            {registrations.map((it) => (
              <>
                <div className="border-b border-black">{it.first}</div>
                <div className="border-b border-black">{it.last}</div>
                <div className="border-b border-black">{it.email}</div>
                <div className="border-b border-black">
                  {new Date(it.time).toLocaleString()}
                </div>
                <div className="border-b border-black">{it.campaign}</div>
              </>
            ))}
          </div>
          <div className="flex flex-row justify-center">
            <button
              className={`hover:bg-gray-400 bg-gray-300  text-gray-800 font-bold mx-1 py-2 px-4 rounded-l my-3 justify-center`}
              onClick={() =>
                getEntities('email', setRegistrations, initState.uid)
              }
            >
              Refresh
            </button>
            <button
              className={`hover:bg-gray-400 bg-gray-300  text-gray-800 font-bold mx-1 py-2 px-4 rounded-l my-3 justify-center`}
              onClick={() =>
                downloadTxtFile(
                  registrations.map((it) => ({
                    ...it,
                    time: `"${new Date(it.time).toLocaleString()}"`,
                  })),
                )
              }
            >
              Download
            </button>
          </div>
        </>
      )}
      {!user && (
        <Switch>
          {/* PROTECTED URLS WILL REFER TO LOGIN PAGE*/}

          <Route
            path="/blog/:campaignId"
            component={(params) => (
              <Blog
                initState={{
                  ...initState,
                  campaign: params.match.params.campaignId,
                }}
              />
            )}
          />

          <Route
            path="/poll/:campaignId"
            component={(params) => (
              <Ebook2
                initState={{
                  ...initState,
                  campaign: params.match.params.campaignId,
                }}
              />
            )}
          />
          <Route
            path="/ebook/:campaignId"
            component={(params) => (
              <EBook
                initState={{
                  ...initState,
                  campaign: params.match.params.campaignId,
                }}
              />
            )}
          />

          <Route
            component={(params) => (
              <EBook
                initState={{
                  ...initState,
                  campaign: 'brain',
                }}
              />
            )}
          />
        </Switch>
      )}
    </>
  );
};

const QueryForm = ({ initState, success, checkOk, info }) => {
  const [check, setCheck] = React.useState(false);
  const [view, setView] = React.useState(0);
  const [state, setState] = React.useState(initState);
  const disabled = false;

  let content = info
    ? info
    : {
        title: 'Brain food',
        image: 'https://app.puur-eva.be/brain.jpg',
        description: "Schrijf je in en ontvang GRATIS het e-boek 'Brain food'",
        buttonText: 'e-Boek downloaden via link',
      };
  const pattern = (pattern) => {
    return pattern ? pattern : /^.+$/;
  };

  return (
    <form className="flex flex-col items-center">
      <div className="text-2xl sm:text-5xl my-8">{content.title}</div>
      <img width="400px" src={content.image}></img>
      <p className="text-xl sm:text-2xl my-8">{content.description}</p>
      <div className="flex flex-row gap-2">
        <input
          type="text"
          title="Voornaam"
          placeholder="voornaam"
          value={state.first}
          onChange={(e) => {
            setState({ ...state, first: e.target.value });
          }}
          className={`${
            check && !pattern().test(state.first) && 'bg-red-300'
          } form-input appearance-none max-w-sm `}
        ></input>
        <input
          type="text"
          title="Familie naam"
          placeholder="familie naam"
          value={state.last}
          onChange={(e) => {
            setState({ ...state, last: e.target.value });
          }}
          className={`${
            check && !pattern().test(state.last) && 'bg-red-300'
          } form-input appearance-none max-w-sm `}
        ></input>
      </div>
      <input
        type="email"
        title="Contract start maand"
        placeholder="jouw.naam@mail.com"
        value={state.email}
        onChange={(e) => {
          setState({ ...state, email: e.target.value });
        }}
        className={`${
          check &&
          !pattern(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/).test(
            state.email,
          ) &&
          'bg-red-300'
        } form-input appearance-none max-w-sm `}
      ></input>
      <div className="flex flex-row gap-2 my-2">
        <input
          id="newsletters"
          type="checkbox"
          className="w-4 h-4 text-indigo-600 transition duration-150 ease-in-out"
          checked={state.newsletter}
          onChange={(e) => {
            setState({ ...state, newsletter: e.target.checked });
          }}
        ></input>
        <label
          htmlFor="remember_me"
          className="block ml-2 text-sm leading-5 text-gray-900"
        >
          Ik wens de newsletter te ontvangen
        </label>
      </div>

      {view === 1 ? (
        success()
      ) : (
        <>
          <a
            className={`max-w-xs bg-gray-300 text-gray-800 font-bold mx-1 py-2 px-4 rounded-l my-3 text-center`}
            onClick={async (e) => {
              e.preventDefault();
              if (
                pattern().test(state.first) &&
                pattern().test(state.last) &&
                pattern(
                  /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                ).test(state.email)
              ) {
                checkOk(state, setView);
              } else {
                setCheck(true);
              }
            }}
          >
            {content.buttonText}
          </a>
        </>
      )}
    </form>
  );
};

const Ebook2 = ({ initState }) => {
  return (
    <QueryForm
      initState={initState}
      success={() => <h1>Alvast bedankt voor jouw interesse!</h1>}
      checkOk={(state, setView) => {
        createEntity(`/user_data/${initState.uid}/email`, {
          ...state,
          time: Date.now(),
        }).then(() => {
          setView(1);
          window.location.href =
            'https://firebasestorage.googleapis.com/v0/b/puur-eva-be.appspot.com/o/brain-food.pdf?alt=media&token=230d911a-b18a-4da2-b917-32498567aa69';
        });
      }}
    ></QueryForm>
  );
};
const EBook = ({ initState }) => {
  const [check, setCheck] = React.useState(false);
  const [view, setView] = React.useState(0);
  const [state, setState] = React.useState(initState);
  const disabled = false;

  const pattern = (pattern) => {
    return pattern ? pattern : /^.+$/;
  };

  return (
    <form className="flex flex-col items-center">
      <div className="text-2xl sm:text-5xl my-8">Brain food</div>
      <img src="https://app.puur-eva.be/brain.jpg"></img>
      <p className="text-xl sm:text-2xl my-8">
        Schrijf je in en ontvang GRATIS het e-boek 'Brain food'
      </p>
      <div className="flex flex-row gap-2">
        <input
          type="text"
          title="Voornaam"
          placeholder="voornaam"
          value={state.first}
          onChange={(e) => {
            setState({ ...state, first: e.target.value });
          }}
          className={`${
            check && !pattern().test(state.first) && 'bg-red-300'
          } form-input appearance-none max-w-sm `}
        ></input>
        <input
          type="text"
          title="Familie naam"
          placeholder="familie naam"
          value={state.last}
          onChange={(e) => {
            setState({ ...state, last: e.target.value });
          }}
          className={`${
            check && !pattern().test(state.last) && 'bg-red-300'
          } form-input appearance-none max-w-sm `}
        ></input>
      </div>
      <input
        type="email"
        title="Contract start maand"
        placeholder="jouw.naam@mail.com"
        value={state.email}
        onChange={(e) => {
          setState({ ...state, email: e.target.value });
        }}
        className={`${
          check &&
          !pattern(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/).test(
            state.email,
          ) &&
          'bg-red-300'
        } form-input appearance-none max-w-sm `}
      ></input>
      <div className="flex flex-row gap-2 my-2">
        <input
          id="newsletters"
          type="checkbox"
          className="w-4 h-4 text-indigo-600 transition duration-150 ease-in-out"
          checked={state.newsletter}
          onChange={(e) => {
            setState({ ...state, newsletter: e.target.checked });
          }}
        ></input>
        <label
          htmlFor="remember_me"
          className="block ml-2 text-sm leading-5 text-gray-900"
        >
          Ik wens de newsletter te ontvangen {view}
        </label>
      </div>

      {view === 1 ? (
        <h1>Alvast bedankt voor jouw interesse!</h1>
      ) : (
        <>
          <a
            className={`max-w-xs bg-gray-300 text-gray-800 font-bold mx-1 py-2 px-4 rounded-l my-3 text-center`}
            onClick={async (e) => {
              e.preventDefault();
              if (
                pattern().test(state.first) &&
                pattern().test(state.last) &&
                pattern(
                  /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                ).test(state.email)
              ) {
                createEntity(`/user_data/${state.uid}/email`, {
                  ...state,
                  time: Date.now(),
                }).then(() => {
                  //setState(initState);
                  setCheck(false);
                  setView(1);
                  window.location.href =
                    'https://firebasestorage.googleapis.com/v0/b/puur-eva-be.appspot.com/o/brain-food.pdf?alt=media&token=230d911a-b18a-4da2-b917-32498567aa69';
                });
              } else {
                setCheck(true);
              }
            }}
          >
            e-Boek downloaden via link
          </a>
        </>
      )}
    </form>
  );
};

export default PuurEva;
