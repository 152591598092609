import React, { useState, useEffect, Fragment } from 'react';
import { CONTRACT_REGION, getMonth } from '../../model/StaticProperties';
import { calcIndex, createEntity } from '../../firebase';
import RentAdjustmentDetail from './RentAdjustmentDetail';
import { contractRentAdjustment } from '../../model/ContractRentAdjustment';
const CURRENT_YEAR = new Date().getFullYear();
const lang = 'nl';

const WizardAdditionalDetail = ({
  handleInputChange,
  getValue,
  validateField,
  setValue,
  indexResult,
}) => {
  const [takePropertyAddress, setPropertyAddress] = useState(
    getValue('tenant.usePropertAddress'),
  );
  return (
    <Fragment>
      <div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          <div className="sm:col-span-2 lg:col-span-1 ">
            <div className="grid sm:grid-cols-address gap-2">
              <h2 className="sm:col-span-2 my-3 text-xl font-medium text-center">
                Adres van het pand
              </h2>

              <div className="sm:col-span-2">
                <label>Straat en nummer</label>
                <input
                  type="text"
                  required
                  title="Straat en nummer"
                  value={getValue('address.street')}
                  placeholder="Straat en nummer van het pand"
                  onChange={handleInputChange('address.street')}
                  className="form-input appearance-none"
                ></input>
              </div>
              <div>
                <label>Postcode</label>
                <input
                  type="number"
                  min="1000"
                  title="Postcode"
                  required
                  value={getValue('address.postalcode')}
                  placeholder="Postcode"
                  onChange={handleInputChange('address.postalcode')}
                  className="form-input appearance-none"
                ></input>
              </div>
              <div>
                <label>Plaats</label>
                <input
                  type="text"
                  title="Plaats"
                  required
                  value={getValue('address.place')}
                  placeholder="Plaats van het pand"
                  onChange={handleInputChange('address.place')}
                  className="form-input appearance-none"
                ></input>
              </div>
            </div>
          </div>

          <div>
            <div className="grid sm:grid-cols-1 md:grid-cols-address gap-2">
              <h2 className="md:col-span-2 my-3 text-xl font-medium text-center">
                Gegevens huurder
              </h2>

              <div className="md:col-span-2">
                <label>Naam</label>
                <input
                  type="text"
                  required
                  title="Naam van de huurder"
                  value={getValue('tenant.name')}
                  placeholder="Huurder naam"
                  onChange={handleInputChange('tenant.name')}
                  className="form-input"
                ></input>
              </div>

              <div
                className={`md:col-span-2 ${takePropertyAddress && 'hidden'}`}
              >
                <label>Straat en nummer</label>
                <input
                  type="text"
                  title="Straat en nummer"
                  required={!takePropertyAddress}
                  value={getValue('tenant.address.street')}
                  placeholder="Huurder straat en nummer"
                  onChange={handleInputChange('tenant.address.street')}
                  className="form-input appearance-none"
                ></input>
              </div>
              <div className={`${takePropertyAddress && 'hidden'}`}>
                <label>Postcode</label>
                <input
                  type="number"
                  required={!takePropertyAddress}
                  min="1000"
                  title="Postcode"
                  value={getValue('tenant.address.postalcode')}
                  placeholder="Postcode"
                  onChange={handleInputChange('tenant.address.postalcode')}
                  className="form-input appearance-none"
                ></input>
              </div>
              <div className={`${takePropertyAddress && 'hidden'}`}>
                <label>Plaats</label>
                <input
                  type="text"
                  title="Plaats"
                  required={!takePropertyAddress}
                  value={getValue('tenant.address.place')}
                  placeholder="Huurder plaats"
                  onChange={handleInputChange('tenant.address.place')}
                  className="form-input appearance-none"
                ></input>
              </div>

              <div className="md:col-span-2">
                <input
                  className="mr-2 leading-tight"
                  type="checkbox"
                  onChange={() => {
                    setValue('tenant.usePropertAddress', !takePropertyAddress);
                    setPropertyAddress(!takePropertyAddress);
                  }}
                  checked={takePropertyAddress}
                />
                <span>Adress van het pand overnemen</span>
              </div>
            </div>
          </div>
          <div>
            <div className="grid sm:grid-cols-1 md:grid-cols-address gap-2">
              <h2 className="md:col-span-2 my-3 text-xl font-medium text-center">
                Gegevens verhuurder
              </h2>
              <div className="md:col-span-2">
                <label>Naam</label>
                <input
                  type="text"
                  required
                  title="Naam van de verhuurder"
                  value={getValue('owner.name')}
                  placeholder="Naam verhuurder"
                  onChange={handleInputChange('owner.name')}
                  className={`form-input  ${
                    !validateField('owner.name') && 'bg-red-300'
                  }`}
                ></input>
              </div>
              <div className="md:col-span-2">
                <label>Straat en nummer</label>
                <input
                  type="text"
                  required
                  title="Straat en nummer"
                  value={getValue('owner.address.street')}
                  placeholder="Verhuurder straat"
                  onChange={handleInputChange('owner.address.street')}
                  className="form-input appearance-none"
                ></input>
              </div>
              <div>
                <label>Postcode</label>
                <input
                  type="number"
                  min="1000"
                  required
                  title="Postcode"
                  value={getValue('owner.address.postalcode')}
                  placeholder="Postcode"
                  onChange={handleInputChange('owner.address.postalcode')}
                  className="form-input appearance-none"
                ></input>
              </div>
              <div>
                <label>Plaats</label>
                <input
                  type="text"
                  title="Plaats"
                  required
                  value={getValue('owner.address.place')}
                  placeholder="Verhuurder plaats"
                  onChange={handleInputChange('owner.address.place')}
                  className="form-input appearance-none"
                ></input>
              </div>
              <div className="md:col-span-2">
                <label>Email</label>
                <input
                  type="text"
                  title="Email"
                  pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
                  required
                  value={getValue('owner.email')}
                  placeholder="verhuurder@mail.com"
                  onChange={handleInputChange('owner.email')}
                  className="form-input appearance-none"
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const WizardContractDetail = ({
  handleInputChange,
  getValue,
  validateField,
  indexResult,
}) => {
  return (
    <Fragment>
      <div>
        <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3">
          <h2 className="col-span-2 md:col-span-3 my-3 text-xl font-medium text-center">
            Indexatie huurcontract
          </h2>
          <div className="col-span-1">
            <label>Huurprijs bij ondertekening</label>
            <input
              type="text"
              title={`Huurprijs bij ondertekening`}
              value={getValue('detail.rent')}
              placeholder="Huurprijs"
              onChange={handleInputChange('detail.rent')}
              className={`form-input appearance-none ${
                !validateField('detail.rent') && 'bg-red-300'
              }`}
            ></input>
          </div>
          <div className="col-span-1">
            <label>Jaar van indexatie</label>
            <select
              name="year"
              title="Jaar van indexatie"
              value={getValue('detail.year')}
              onChange={handleInputChange('detail.year')}
              className="form-select appearance-none"
            >
              {new Array(CURRENT_YEAR - 1994)
                .fill(0)
                .reduce(
                  (acc) => {
                    acc.push(acc[acc.length - 1] - 1);
                    return acc;
                  },
                  [CURRENT_YEAR],
                )
                .map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
            </select>
          </div>

          <div>
            <label>Maand ondertekening</label>
            <input
              type="month"
              title="Comtract ondertekening maand"
              value={getValue('detail.signdate')}
              onChange={handleInputChange('detail.signdate')}
              className="form-input appearance-none"
              className={`form-input appearance-none`}
            ></input>
          </div>
          <div>
            <label>Maand inwerkingtreding</label>
            <input
              type="month"
              title="Contract start maand"
              value={getValue('detail.startdate')}
              onChange={handleInputChange('detail.startdate')}
              className="form-input appearance-none"
            ></input>
          </div>
          <div>
            <label>Hoofdverblijfplaats</label>
            <select
              name="region"
              title="Regio hoofdverblijfplaats"
              value={getValue('detail.region')}
              onChange={handleInputChange('detail.region')}
              className="appearance-none form-select"
            >
              {CONTRACT_REGION.map((item) => (
                <option key={item.key} value={item.key}>
                  {item.value.nl}
                </option>
              ))}
            </select>
          </div>

          {/*Restult*/}

          <div className="col-span-2 md:col-span-3 my-6">
            {indexResult ? (
              indexResult.result === 0 ? (
                <label className="text-lg">
                  Een nieuwe huurprijs van{' '}
                  <label className="text-lg font-bold">
                    {indexResult.newRent.rent} EUR
                  </label>{' '}
                  is geldig vanaf{' '}
                  <label className="text-lg font-bold">
                    {getMonth(indexResult.newRent.fromMonth, lang)}{' '}
                    {indexResult.newRent.fromYear}
                  </label>
                </label>
              ) : (
                <label>{indexResult.msg[lang]}</label>
              )
            ) : (
              <label className="text-lg">
                Vervolledig bovenstaande gegevens
              </label>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const WizardContractIndexResult = ({ getValue, indexResult }) => {
  return indexResult ? (
    indexResult.result === 0 ? (
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 text-base font-ligh text-left">
        <h2 className="sm:col-span-2 text-xl font-medium text-center">
          Indexatie voor {getValue('detail.year')}
        </h2>
        <div className="my-6 sm:text-right my-auto">
          <label className="text-lg">
            Toepassing wettelijke formule nieuwe huurprijs{' '}
            {getMonth(indexResult.newRent.fromMonth, lang)}{' '}
            {indexResult.newRent.fromYear}
          </label>
        </div>
        <div className="my-6 grid grid-cols-1 items-center ">
          <div className="m-auto grid grid-cols-1">
            <label className="text-center pl-3 pr-3 pb-2 ">
              Basishuurprijs ({getMonth(indexResult.baseRent.month, lang)}{' '}
              {indexResult.baseRent.year}) x GI [2013]{' '}
              {getMonth(indexResult.currentIndex.month, lang)}{' '}
              {indexResult.currentIndex.year}{' '}
            </label>
            <label className="text-center border-black border-t-2 pt-2 mb-1">
              GI [2013] {getMonth(indexResult.startIndex.month, lang)}{' '}
              {indexResult.startIndex.year}
            </label>
          </div>
        </div>
        <div className="sm:text-right my-auto">
          <label className="text-lg">Dit geeft in cijfers</label>
        </div>
        <div className="my-6 grid grid-cols-1 items-center ">
          <div className="m-auto grid grid-cols-1">
            <label className="text-center pl-3 pr-3 pb-2 ">
              {parseFloat(getValue('detail.rent')).toFixed(2)} EUR *{' '}
              {indexResult.currentIndex.index}
            </label>
            <label className="text-center border-black border-t-2 pt-2 mb-1">
              {indexResult.startIndex.index}
            </label>
          </div>
        </div>

        <div className="sm:col-span-2 mx-auto my-6">
          <label className="text-lg ">
            Een nieuwe huurprijs van{' '}
            <label className="text-lg font-bold">
              {indexResult.newRent.rent} EUR
            </label>{' '}
            is geldig vanaf{' '}
            <label className="text-lg font-bold">
              {getMonth(indexResult.newRent.fromMonth, lang)}{' '}
              {indexResult.newRent.fromYear}
            </label>
          </label>
        </div>
      </div>
    ) : (
      <Fragment>
        <label>{indexResult.msg[lang]}</label>{' '}
      </Fragment>
    )
  ) : (
    <Fragment></Fragment>
  );
};

const WithActionButtons = ({ children, buttons, top }) => {
  return (
    <div className="m-2 sm:m-4 ">
      {!top && children}
      <div className="flex justify-center">
        {buttons.map((bt, i) => {
          return (
            <button
              key={i}
              disabled={bt.disabled}
              type={bt.type ? bt.type : 'button'}
              className={`${
                bt.disabled ? 'opacity-50' : 'hover:bg-gray-400'
              } bg-gray-300  text-gray-800 font-bold mx-1 py-2 px-4 rounded-l`}
              onClick={bt.onClick}
            >
              {bt.value}
            </button>
          );
        })}
      </div>
      {top && children}
    </div>
  );
};

const ACTION_CHANGE_VIEW = 0;
const CONTRACT_INPUT = 1;
const INDEX_RESULT = 2;
const TENANT_DETAILS = 3;
const INDEX_LETTER = 4;

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_CHANGE_VIEW:
      return { ...state, view: action.view };
  }
  return state;
};

const ContractWizard = () => {
  const [contractRentAdjustmentLocal, setL] = useState({
    ...contractRentAdjustment(),
    contract: {
      detail: { language: 1 },
      address: {},
    },
    owner: {
      address: {},
    },
    property: {
      address: {},
    },
  });

  const deb = false;

  const [entityDetail, setEntityDetail] = useState({
    address: {},
    tenant: {
      usePropertAddress: true,
      address: {},
    },
    owner: {
      address: {},
    },
    detail: {
      signdate: `${CURRENT_YEAR - 3}-01`,
      startdate: `${CURRENT_YEAR - 3}-02`,
      year: CURRENT_YEAR,
      region: 1,
      rent_pattern: /^[1-9]{1}\d*([.]{1}\d{1,2})?$/,
      signdate_pattern: /^\d{3}[\-]\d{2}$/,
      startdate_pattern: /^\d{4}[\-]\d{2}$/,
    },
  });
  const [formValidation, setFormValidation] = useState(false);

  const [indexResult, setIndexResult] = useState();
  useEffect(() => {
    setIndexResult(
      calcIndex(
        parseFloat(getValue('detail.rent')),
        getValue('detail.signdate'),
        getValue('detail.startdate'),
        getValue('detail.year'),
        getValue('detail.region'),
      ),
    );
  }, [entityDetail]);

  const [privacy, setPrivacy] = useState(false);
  const [notify, setNotify] = useState(false);
  const [trySubmit, setTrySubmit] = useState(false);

  useEffect(() => {
    //copy fields
    indexResult &&
      indexResult.result === 0 &&
      setL({
        ...contractRentAdjustmentLocal,
        acceptedPrivacy: privacy,
        notifyMe: notify,
        contract: {
          name: entityDetail.tenant.name,
          address: entityDetail.tenant.usePropertAddress
            ? entityDetail.address
            : entityDetail.tenant.address,
          detail: { language: 2, rent: indexResult.baseRent.rent },
        },
        detail: {
          indexBaseMonthYear: `${indexResult.baseRent.month}/${indexResult.baseRent.year}`,
          baseIndexRebased: indexResult.startIndex.index,
          newIndexMonthYear: `${indexResult.currentIndex.month}/${indexResult.currentIndex.year}`,
          newIndex: indexResult.currentIndex.index,
          rent: indexResult.newRent.rent,
          nextPaymentMonth: `${indexResult.newRent.fromMonth}/${indexResult.newRent.fromYear}`,
          monthlyDue: indexResult.newRent.rent,
        },
        owner: entityDetail.owner,
        property: { address: entityDetail.address },
      });
  }, [indexResult, privacy, notify]);

  const [state, dispatch] = React.useReducer(reducer, {
    view: CONTRACT_INPUT,
  });

  const getValue = (path) => {
    return path.split('.').reduce((acc, key) => acc[key], entityDetail);
  };

  const validateField = (path) => {
    let value = path.split('.').reduce((acc, key) => acc[key], entityDetail);
    let pattern = (path + '_pattern')
      .split('.')
      .reduce((acc, key) => acc[key], entityDetail);

    pattern = pattern ? pattern : /^.+$/;

    let result = value ? pattern.test(value) : !formValidation;

    console.log(`Path: ${path} - ${result} - ${value} `);
    return result;
  };

  const handleInputChange = (path) => (event) => {
    let value = event.target.value;
    setValue(path, value);
  };

  const setValue = (path, value) => {
    let updatedEntity = { ...entityDetail };
    let childEntity = updatedEntity;
    path.split('.').reduce((acc, key, i, pathList) => {
      if (i === pathList.length - 1) childEntity[key] = value;
      else childEntity = childEntity[key];
      return acc[key];
    }, entityDetail);

    setEntityDetail(updatedEntity);
  };

  return (
    <div className="w-full flex flex-col justify-center content-center justify-items-center ml-2 mr-2 mt-6 mb-2">
      {state.view === CONTRACT_INPUT && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (
              indexResult &&
              indexResult.result === 0 &&
              validateField('detail.rent')
            ) {
              setFormValidation(false);
              dispatch({ type: ACTION_CHANGE_VIEW, view: INDEX_RESULT });
            } else setFormValidation(true);
          }}
        >
          <WithActionButtons
            buttons={[
              {
                value: 'Detail',
                type: 'submit',
              },
              {
                value: 'Brief opstellen',
                disabled:
                  indexResult === undefined
                    ? true
                    : indexResult.result !== 0 || !validateField('detail.rent'),
                onClick: () =>
                  dispatch({ type: ACTION_CHANGE_VIEW, view: TENANT_DETAILS }),
              },
            ]}
          >
            <WizardContractDetail
              getValue={getValue}
              validateField={validateField}
              indexResult={indexResult}
              handleInputChange={handleInputChange}
            />
          </WithActionButtons>
        </form>
      )}
      {state.view === INDEX_RESULT && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            dispatch({ type: ACTION_CHANGE_VIEW, view: TENANT_DETAILS });
          }}
        >
          <WithActionButtons
            buttons={[
              {
                value: 'Terug',
                onClick: () =>
                  dispatch({ type: ACTION_CHANGE_VIEW, view: CONTRACT_INPUT }),
              },
              {
                value: 'Brief opstellen',
                type: 'submit',
              },
            ]}
          >
            <WizardContractIndexResult
              getValue={getValue}
              indexResult={indexResult}
              handleInputChange={handleInputChange}
            />
          </WithActionButtons>
        </form>
      )}

      {state.view === TENANT_DETAILS && (
        <form
          onSubmit={(e) => {
            e.preventDefault();

            setTrySubmit(true);
            if (privacy) {
              dispatch({ type: ACTION_CHANGE_VIEW, view: INDEX_LETTER });
              createEntity(
                `contractRentAdjustmentPublic/${getValue(
                  'owner.email',
                )}/contracts`,
                contractRentAdjustmentLocal,
              );
            }
          }}
        >
          <WithActionButtons
            buttons={[
              {
                value: 'Terug',
                onClick: () =>
                  dispatch({ type: ACTION_CHANGE_VIEW, view: CONTRACT_INPUT }),
              },
              {
                value: 'Brief',
                type: 'submit',
              },
            ]}
          >
            <Fragment>
              <WizardAdditionalDetail
                getValue={getValue}
                validateField={validateField}
                setValue={setValue}
                handleInputChange={handleInputChange}
              />
              <div className={`flex justify-center mb-4`}>
                <input
                  className="mr-2 leading-tight my-auto"
                  type="checkbox"
                  onChange={() => {
                    setPrivacy(!privacy);
                  }}
                  checked={privacy}
                />
                <span
                  className={`${
                    trySubmit && !privacy && 'text-red-600 font-bold'
                  }`}
                >
                  {`Ik ga akkoor met de `}
                </span>
                <a
                  className="text-blue-600 ml-1"
                  href="/privacy.html"
                  target="_blank"
                >
                  {`privacy verklaring`}
                </a>
              </div>

              <div className="flex justify-center mb-4">
                <input
                  className="mr-2 leading-tight my-auto"
                  type="checkbox"
                  onChange={() => {
                    setNotify(!notify);
                  }}
                  checked={notify}
                />
                <span>Graag jaarlijkse notificatie per email</span>
              </div>
            </Fragment>
          </WithActionButtons>
        </form>
      )}

      {state.view === INDEX_LETTER && (
        <WithActionButtons
          top={true}
          buttons={[
            {
              value: 'Terug',
              onClick: () =>
                dispatch({ type: ACTION_CHANGE_VIEW, view: TENANT_DETAILS }),
            },

            {
              value: 'Print',
              onClick: () => {
                window.print();
              },
            },
          ]}
        >
          <RentAdjustmentDetail
            contractRentAdjustment={contractRentAdjustmentLocal}
          ></RentAdjustmentDetail>
        </WithActionButtons>
      )}
    </div>
  );
};

export default ContractWizard;
