import React from 'react';

const RegisterPage = ({ match }) => {
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-2xl px-3 my-8">
      <div className="sm:rounded-lg sm:px-10 px-4 py-8 bg-white shadow">
        <form
        // onSubmit={ }
        >
          {/* First Name */}
          <label
            htmlFor="first_name"
            className="block text-sm font-medium leading-5 text-gray-700 cursor-pointer"
          >
            First Name
          </label>
          <input
            // onChange={}
            // value={}
            name="first_name"
            id="first_name"
            type="text"
            title="Please enter your first name"
            placeholder="First Name..."
            required
            className="form-input"
          ></input>

          {/* Last Name */}
          <label
            htmlFor="last_name"
            className="block text-sm font-medium leading-5 text-gray-700 cursor-pointer"
          >
            Last Name
          </label>
          <input
            // onChange={}
            // value={}
            name="last_name"
            id="last_name"
            type="text"
            title="Please enter your last name"
            placeholder="Last Name..."
            required
            className="form-input"
          ></input>

          {/* DOB Date of birth */}
          <label
            htmlFor="dob"
            className="block text-sm font-medium leading-5 text-gray-700 cursor-pointer"
          >
            Date of birth
          </label>
          <input
            // onChange={}
            // value={}
            name="dob"
            id="dob"
            type="date"
            title="Please select your date of birth"
            required
            className="form-input"
          ></input>

          {/* Email */}
          <div className="mt-10">
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-5 text-gray-700 cursor-pointer"
            >
              Email
            </label>

            <input
              // onChange={}
              // value={}
              name="email"
              id="email"
              type="text"
              title="Please enter a valid email address"
              placeholder="Email..."
              required
              className="form-input"
            ></input>
          </div>

          {/* Password */}
          <label
            htmlFor="password"
            className="block text-sm font-medium leading-5 text-gray-700 cursor-pointer"
          >
            Password
          </label>
          <input
            // onChange={}
            // value={}
            name="password"
            id="password"
            type="password"
            title="Please enter a valid password"
            placeholder="Password..."
            required
            className="form-input"
          ></input>

          {/* Repeat Password */}
          <label
            htmlFor="repeat_password"
            className="block text-sm font-medium leading-5 text-gray-700 cursor-pointer"
          >
            Repeat Password
          </label>
          <input
            // onChange={}
            // value={}
            name="repeat_password"
            id="repeat_password"
            type="password"
            title="Please repeat your password"
            placeholder="Repeat password..."
            required
            className="form-input"
          ></input>

          {/* CHECKBOX' */}
          <div className="flex items-center">
            <input
              id="accept"
              type="checkbox"
              className="w-4 h-4 text-indigo-600 transition duration-150 ease-in-out"
            ></input>
            <label
              htmlFor="accept"
              className="block ml-2 text-sm leading-5 text-gray-900"
            >
              Accept our{' '}
              <a href="#" className="underline">
                rules of conduct
              </a>
            </label>
          </div>

          <div className="flex items-center mt-3">
            <input
              id="newsletter"
              type="checkbox"
              className=" w-4 h-4 text-indigo-600 transition duration-150 ease-in-out"
            ></input>
            <label
              htmlFor="newsletter"
              className="block ml-2 text-sm leading-5 text-gray-900"
            >
              I want to receive our newsletter
            </label>
          </div>

          {/* SUBMIT  */}
          <div className="mt-6 text-center">
            <button
              type="submit"
              title="Submit account registration"
              className="hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 flex justify-center w-full px-4 py-3 text-sm font-medium text-white transition duration-150 ease-in-out bg-indigo-600 border border-transparent rounded-md"
            >
              Submit Registration
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegisterPage;
