import React from 'react';

const LoginPage = ({ handleSubmit, handleInputChange, loginData }) => {
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-2xl px-3 my-8">
      <div className="sm:rounded-lg sm:px-10 px-4 py-8 bg-white shadow">
        <form onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-5 text-gray-700 cursor-pointer"
            >
              Email address
            </label>

            <input
              onChange={handleInputChange}
              value={loginData.email}
              name="email"
              id="email"
              type="text"
              title="Please enter your email"
              placeholder="email..."
              required
              className="form-input"
            ></input>
          </div>

          <label
            htmlFor="password"
            className="block text-sm font-medium leading-5 text-gray-700 cursor-pointer"
          >
            Password
          </label>

          <input
            onChange={handleInputChange}
            value={loginData.password}
            name="password"
            id="password"
            type="password"
            title="Please enter your password"
            placeholder="password..."
            required
            className="form-input"
          ></input>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember_me"
                type="checkbox"
                className="w-4 h-4 text-indigo-600 transition duration-150 ease-in-out"
              ></input>
              <label
                htmlFor="remember_me"
                className="block ml-2 text-sm leading-5 text-gray-900"
              >
                Remember me
              </label>
            </div>

            <div className="text-sm leading-5">
              <a
                href="#"
                className="hover:text-indigo-500 focus:outline-none focus:underline font-medium text-indigo-600 transition duration-150 ease-in-out"
              >
                Forgot your password?
              </a>
            </div>
          </div>

          <span className="block w-full mt-6 rounded-md">
            <button
              type="submit"
              title="Click to sign in"
              className="hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 flex justify-center w-full px-4 py-3 text-sm font-medium text-white transition duration-150 ease-in-out bg-indigo-600 border border-transparent rounded-md"
            >
              Sign in
            </button>
          </span>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
