import React from 'react';
import DataProvider from './provider/DataProvider';
import AuthProvider, { Login, UserContext } from './provider/AuthProvider';
import PuurEva from './applications/PuurEva.js';
import LandingPage from './components/rentmanagement/LandingPage';
import { NavLink, Switch, Route } from 'react-router-dom';

const Header = ({ home, navlinks }) => {
  const [_, LoginLogout] = React.useContext(UserContext);
  return (
    <div className="Header bg-white shadow-md">
      <div className="container flex flex-wrap items-center px-3 py-6 mx-auto font-sans text-gray-700">
        {/* LOGO */}
        <div className="sm:mx-2">
          <a onClick={home} className="text-indigo-500 active:outline-none">
            <div className="sm:w-16 sm:h-16 hover:shadow-md w-8 h-8">
              <img src="./logo512.png" />
            </div>
          </a>
        </div>

        {/* BUTTONS */}
        <div className="justify-items-stretch flex items-center justify-between flex-grow">
          {/* ACTIONS */}
          <div className="flex flex-row">
            {navlinks &&
              navlinks.map((navLink, i) => (
                <div key={i} className="sm:mx-2">
                  {navLink}
                </div>
              ))}
          </div>

          {/* LOGIN/OUT */}
          <LoginLogout />
        </div>
      </div>
    </div>
  );
};

function App() {
  return (
    <div className="relative flex flex-col h-full">
      <AuthProvider
        publicpage={
          <>
            <div className="xl:max-w-5xlxl flex-grow w-full px-5 mx-auto my-8">
              <PuurEva />
            </div>
          </>
        }
        header={(goToHome, navLinks) => <></>}
        protectedpage={(user) => (
          <div className="xl:max-w-5xlxl flex-grow w-full px-5 mx-auto my-8">
            <PuurEva user={user} />
          </div>
        )}
        footer={<></>}
      ></AuthProvider>
    </div>
  );
}

export default App;
