import React, { createContext, useEffect, useState } from 'react';
import { auth, signInWithEmail, getUser } from '../firebase';
import { NavLink, Switch, Route } from 'react-router-dom';
import LoginPage from '../components/authentication/LoginPage';
import RegisterPage from '../components/authentication/RegisterPage';
import { useHistory } from 'react-router-dom';

export const UserContext = createContext();

export const Login = ({ setUser }) => {
  const [loginData, setLoginData] = useState({ email: '', password: '' });

  const handleInputChange = (event) => {
    setLoginData({ ...loginData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    console.dir(loginData);
    event.preventDefault();
    signInWithEmail(loginData.email, loginData.password, (user) => {
      setUser(user);
      console.dir(user);
    });
  };

  return (
    <LoginPage
      handleSubmit={handleSubmit}
      handleInputChange={handleInputChange}
      loginData={loginData}
    />
  );
};

const ACTION_CHANGE_VIEW = 0;
const PUBLIC = 0;
const LOGIN = 1;
const REGISTER = 2;
const PROTECTED = 3;

const ACTION_LOGIN_STATE = 1;
const LOGIN_USER = 0;
const LOGOUT = 1;

const protectedNavLinks = [
  <>
    <NavLink
      to="/rental_contract"
      className="text-indigo-500 active:outline-none"
    >
      <div className="hover:underline active:outline-none px-2 py-1">
        Huur contracten
      </div>
    </NavLink>
  </>,
  <>
    <NavLink to="/owner" className="text-indigo-500 active:outline-none">
      <div className="hover:underline active:outline-none px-2 py-1">
        Eigenaars
      </div>
    </NavLink>
  </>,
  <>
    <NavLink to="/index" className="text-indigo-500 active:outline-none">
      <div className="hover:underline active:outline-none px-2 py-1">
        Huur indexatie
      </div>
    </NavLink>
  </>,
];
const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_LOGIN_STATE:
      switch (action.subtype) {
        case LOGIN_USER:
          return {
            ...state,
            navLinks: protectedNavLinks,
            user: action.user,
            view: PROTECTED,
          };
        case LOGOUT:
          return { ...state, navLinks: [], user: undefined, view: PUBLIC };
      }

    case ACTION_CHANGE_VIEW:
      return { ...state, view: action.view };
  }
  return state;
};

const AuthProvider = ({ header, footer, publicpage, protectedpage }) => {
  const [state, dispatch] = React.useReducer(reducer, {
    view: PUBLIC,
    navLinks: [],
  });

  const logOut = () => {
    auth.signOut();
    dispatch({ type: ACTION_LOGIN_STATE, subtype: LOGOUT });
  };

  const history = useHistory();

  const setUser = (user) => {
    user && dispatch({ type: ACTION_LOGIN_STATE, subtype: LOGIN_USER, user });
  };

  useEffect(() => {
    const unsubscribeFromAuth = auth.onAuthStateChanged(async (userAuth) => {
      getUser(setUser);
    });

    return () => {
      unsubscribeFromAuth();
    };
  }, []);

  const LoginLogout = () => {
    return (
      <div className="sm:mx-2 flex flex-row flex-wrap items-center">
        {/*<img
          src="https://s3.amazonaws.com/uifaces/faces/twitter/marakasina/128.jpg"
          className="sm:inline-flex hidden w-10 h-10 mr-3 rounded-full shadow-lg"
        />*/}

        <>
          {state.user && (
            <>
              <div className="sm:inline-flex hidden mr-5">{`Welkom ${state.user.name}`}</div>
              <button
                onClick={logOut}
                className="hover:text-red-600 px-2 py-1 cursor-pointer"
                title="Logout"
              >
                <svg
                  className="inline-flex w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                  />
                </svg>
              </button>
            </>
          )}
        </>

        <>
          {state.view !== LOGIN && state.view !== PROTECTED && (
            <>
              <button
                className="hover:text-red-600 px-2 py-1 cursor-pointer"
                onClick={() =>
                  dispatch({ type: ACTION_CHANGE_VIEW, view: LOGIN })
                }
              >
                LOGIN
              </button>
              {/*
              <div
                className="mx-2"
                onClick={() =>
                  dispatch({ type: ACTION_CHANGE_VIEW, view: REGISTER })
                }
              >
                REGISTER
              </div>*/}
            </>
          )}
        </>
      </div>
    );
  };

  return (
    <UserContext.Provider value={[state.user, LoginLogout]}>
      <>
        {header(
          () =>
            state.user === undefined
              ? dispatch({ type: ACTION_CHANGE_VIEW, view: PUBLIC })
              : history.push('/'),
          state.navLinks,
        )}
        <Switch>
          {/* PROTECTED URLS WILL REFER TO LOGIN PAGE*/}
          {state.user === undefined && (
            <Route
              exact
              path="/puur-eva"
              component={() => <Login setUser={setUser}></Login>}
            />
          )}

          <Route
            component={() => (
              <>
                <>{state.view === PUBLIC && publicpage}</>
                <>{state.view === LOGIN && <Login setUser={setUser}></Login>}</>
                <>
                  {state.view === REGISTER && (
                    <RegisterPage setUser={setUser}></RegisterPage>
                  )}
                </>
                <>
                  {state.view === PROTECTED && <>{protectedpage(state.user)}</>}
                </>
              </>
            )}
          />
        </Switch>
        {footer}
      </>
    </UserContext.Provider>
  );
};

export default AuthProvider;
